<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="transportationOptions"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Transportation Options
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Transportation Option
              </v-btn>
            </template>
            <TransportationOptionForm
              @created="handleCreatedTransportationOption"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/transportationOptions/${item._id}`"
          @delete="deleteTransportationOption(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TransportationOptionForm from './TransportationOptionForm'
import { mapActions } from 'vuex'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminTransportationOptions',
  components: {
    TransportationOptionForm,
    EditAndDeleteButtons
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Modifier ID',
          value: 'modifierId'
        },
        {
          text: 'Carbon Factor (kgCO₂e/km)',
          value: 'carbonFactor'
        },
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      transportationOptions: [],
      showForm: false
    }
  },
  created () {
    this.fetchTransportationOptions()
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async fetchTransportationOptions () {
      const { data: transportationOptions } = await this.$axios.get('/transportation_options')
      this.transportationOptions = transportationOptions.map(transportationOption => ({
        ...transportationOption
      }))
    },
    async deleteTransportationOption (id) {
      try {
        await this.$axios.delete(`/transportation_options/${id}`)
        await this.fetchTransportationOptions()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete transportation option. Please try again.' })
        throw err
      }
    },
    handleCreatedTransportationOption () {
      this.showForm = false
      this.fetchTransportationOptions()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
