export const transportationOptionsFields = [
  {
    name: 'modifierId',
    label: 'Modifier Id',
    prefix: 't'
  },
  {
    name: 'carbonFactor',
    label: 'Carbon Factor',
    suffix: 'kgCO2e/kg',
    prefix: 't'
  }
]
export const transportationOptionsForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  ...transportationOptionsFields,
  {
    name: 'notes',
    label: 'Notes'

  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type !== 'hydrozone'
}
